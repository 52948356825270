/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import App from 'App'
import { COLOR_CONSTANTS, radius } from 'theme'
import { CONTAINER_MAX_WIDTH, AUTHORS } from 'consts'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import NewsletterSubScription from 'components/NewsletterSubScription'
import Footer from 'components/Footer'
import { Text, H3 } from 'components/Typography'
import Container from 'components/Container'
import LocalImage from 'components/LocalImage'
import HeaderSection from './components/HeaderSection'
import CategoryItem from './components/CategoryItem'
import BlogItem from './components/BlogItem'
import PaginationComponent from './components/PaginationComponent'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledAuthorImage = styled(LocalImage)`
  width: 96px;
  height: 96px;
  border-radius: ${radius.pill};
`

const BlogAuthor = ({
  pageContext: { numPages, currentPage, categories, pagePath, name, description = '', image, imageMapping },
  data: {
    allWordpressPost: { edges },
  },
}) => {
  const edgeToNode = ({ node }) => {
    // console.log('edgetonode')
    const { featured_media } = node
    if (featured_media && featured_media.source_url) {
      if (imageMapping[featured_media.source_url]) {
        featured_media.source_url = imageMapping[featured_media.source_url]
      }

      // featured_media.source_url = featured_media.source_url.replace(
      //   'https://cms.vistasocial.com/wp-content/uploads/',
      //   'https://dzjj3fk5puw20.cloudfront.net/blog/'
      // )
    }

    return node
  }
  const articles = edges.map(edgeToNode)

  const isFirstPage = currentPage === 1
  const nameCleaned = name.indexOf('|') > -1 ? name.substr(0, name.indexOf('|') - 1) : name

  const chunkOfArticles = articles.slice(0, 24)

  const strippedDescription = description && description.replace(/(<([^>]+)>)/gi, '')

  const strippedDescriptionCleaned =
    strippedDescription.indexOf('|') > -1
      ? strippedDescription.substr(0, strippedDescription.indexOf('|') - 1)
      : strippedDescription

  return (
    <App>
      <SEO
        title={`${nameCleaned} Author Bio and Latest Articles at Vista Social`}
        path={`${pagePath}${isFirstPage ? '' : `/${currentPage}`}`}
        description={`A list of all the articles and guides written and published by ${nameCleaned} at the Vista Social Marketing Blog.`}
      />

      <Wrapper>
        <Container
          pt={isFirstPage ? 'xl' : 'l'}
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
        >
          <Flex pb={isFirstPage ? '0' : 'l'} flexDirection="column">
            <Flex alignItems="center">
              <StyledAuthorImage src={image} />
              <Flex ml="m" flexDirection="column">
                <H3 fontWeight="bold" fontSize="3xl" color={COLOR_CONSTANTS.DENIM} textAlign="left">
                  {nameCleaned}
                </H3>
                <Text mt="s" color="secondaryText" fontSize="m" textAlign="left">
                  {AUTHORS.getTitle(nameCleaned)}
                </Text>
              </Flex>
            </Flex>
            {strippedDescriptionCleaned && (
              <Text mt="s" color="secondaryText" fontSize="m" textAlign="left">
                {strippedDescriptionCleaned}
              </Text>
            )}
          </Flex>

          <Grid
            mt="l"
            gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(2, 1fr)', desktop: 'repeat(3, 1fr)' }}
            gridGap="m"
          >
            {chunkOfArticles.map((article) => (
              <BlogItem key={article.id} {...article} />
            ))}
          </Grid>

          <Flex flexDirection="column">
            <Text color="secondaryText" mt="m" pt="s" fontSize="l" textAlign="left">
              Choose the topic you’re interested in
            </Text>
          </Flex>

          {categories.length > 0 && (
            <Flex
              mt={isFirstPage ? 's' : 's'}
              pt={isFirstPage ? 's' : 0}
              width="100%"
              justifyContent="flex-start"
              flexWrap="wrap"
            >
              {categories.map((category, index) => (
                <CategoryItem key={index} category={category} fontSize="s" />
              ))}
            </Flex>
          )}
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          mt="xl"
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
        >
          <PaginationComponent
            currentPage={currentPage}
            totalCount={numPages}
            pageSize={1}
            onPageChange={(page) => {
              window.open(`${pagePath}${page === 1 ? '' : page}`, '_self')
            }}
          />
        </Container>
      </Wrapper>

      <Flex mt="xxl" width="100%" alignItems="center" justifyContent="center">
        <NewsletterSubScription type="insights" />
      </Flex>

      <Footer />
    </App>
  )
}

BlogAuthor.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default BlogAuthor

export const blogAuthorQuery = graphql`
  query blogAuthorQuery($skip: Int!, $limit: Int!, $filter: String!) {
    allWordpressPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { categories: { elemMatch: { slug: { ne: "internal" } } }, author: { slug: { eq: $filter } } }
    ) {
      edges {
        node {
          id
          path
          title
          content
          date
          modified
          slug
          author {
            name
            avatar_urls {
              wordpress_96
            }
          }
          categories {
            slug
            name
            description
          }
          featured_media {
            title
            alt_text
            caption
            source_url
          }
        }
      }
    }
  }
`
